import React from "react";
//import { Line } from "react-svg-curve";
const gdpdata = [
  { year: 1791, num: 0.3 },
  { year: 1792, num: 0.3 },
  { year: 1793, num: 0.3 },
  { year: 1794, num: 0.4 },
  { year: 1795, num: 0.4 },
  { year: 1796, num: 0.5 },
  { year: 1797, num: 0.5 },
  { year: 1798, num: 0.5 },
  { year: 1799, num: 0.5 },
  { year: 1800, num: 0.5 },
  { year: 1801, num: 0.6 },
  { year: 1802, num: 0.6 },
  { year: 1803, num: 0.6 },
  { year: 1804, num: 0.6 },
  { year: 1805, num: 0.7 },
  { year: 1806, num: 0.7 },
  { year: 1807, num: 0.7 },
  { year: 1808, num: 0.6 },
  { year: 1809, num: 0.7 },
  { year: 1810, num: 0.8 },
  { year: 1811, num: 0.8 },
  { year: 1812, num: 0.8 },
  { year: 1813, num: 1 },
  { year: 1814, num: 1.2 },
  { year: 1815, num: 1.2 },
  { year: 1816, num: 1.3 },
  { year: 1817, num: 1.3 },
  { year: 1818, num: 1.4 },
  { year: 1819, num: 1.2 },
  { year: 1820, num: 1.1 },
  { year: 1821, num: 1 },
  { year: 1822, num: 1.1 },
  { year: 1823, num: 1.1 },
  { year: 1824, num: 1.1 },
  { year: 1825, num: 1.2 },
  { year: 1826, num: 1.2 },
  { year: 1827, num: 1.2 },
  { year: 1828, num: 1.2 },
  { year: 1829, num: 1.2 },
  { year: 1830, num: 1.2 },
  { year: 1831, num: 1.3 },
  { year: 1832, num: 1.5 },
  { year: 1833, num: 1.6 },
  { year: 1834, num: 1.6 },
  { year: 1835, num: 1.7 },
  { year: 1836, num: 1.7 },
  { year: 1837, num: 1.9 },
  { year: 1838, num: 1.8 },
  { year: 1839, num: 1.9 },
  { year: 1840, num: 1.8 },
  { year: 1841, num: 1.8 },
  { year: 1842, num: 1.7 },
  { year: 1843, num: 1.9 },
  { year: 1844, num: 2 },
  { year: 1845, num: 2.1 },
  { year: 1846, num: 2.3 },
  { year: 1847, num: 2.5 },
  { year: 1848, num: 2.5 },
  { year: 1849, num: 2.5 },
  { year: 1850, num: 2.8 },
  { year: 1851, num: 2.8 },
  { year: 1852, num: 3.2 },
  { year: 1853, num: 3.6 },
  { year: 1854, num: 3.6 },
  { year: 1855, num: 4 },
  { year: 1856, num: 4.2 },
  { year: 1857, num: 4 },
  { year: 1858, num: 4.3 },
  { year: 1859, num: 4.1 },
  { year: 1860, num: 4.1 },
  { year: 1861, num: 4.2 },
  { year: 1862, num: 4.9 },
  { year: 1863, num: 6.2 },
  { year: 1864, num: 8.8 },
  { year: 1865, num: 8.8 },
  { year: 1866, num: 8.6 },
  { year: 1867, num: 8.3 },
  { year: 1868, num: 8.4 },
  { year: 1869, num: 8.3 },
  { year: 1870, num: 8.5 },
  { year: 1871, num: 8.8 },
  { year: 1872, num: 8.9 },
  { year: 1873, num: 9.3 },
  { year: 1874, num: 9 },
  { year: 1875, num: 9 },
  { year: 1876, num: 8.8 },
  { year: 1877, num: 8.9 },
  { year: 1878, num: 8.7 },
  { year: 1879, num: 9.5 },
  { year: 1880, num: 11.2 },
  { year: 1881, num: 11.5 },
  { year: 1882, num: 12.5 },
  { year: 1883, num: 12.4 },
  { year: 1884, num: 12.1 },
  { year: 1885, num: 11.8 },
  { year: 1886, num: 12.2 },
  { year: 1887, num: 12.8 },
  { year: 1888, num: 12.9 },
  { year: 1889, num: 13.7 },
  { year: 1890, num: 13.6 },
  { year: 1891, num: 14 },
  { year: 1892, num: 14.5 },
  { year: 1893, num: 14.6 },
  { year: 1894, num: 13.4 },
  { year: 1895, num: 14.7 },
  { year: 1896, num: 14.4 },
  { year: 1897, num: 15.4 },
  { year: 1898, num: 15.9 },
  { year: 1899, num: 18 },
  { year: 1900, num: 18.8 },
  { year: 1901, num: 21.2 },
  { year: 1902, num: 21.8 },
  { year: 1903, num: 23 },
  { year: 1904, num: 24.1 },
  { year: 1905, num: 26.3 },
  { year: 1906, num: 28.3 },
  { year: 1907, num: 29.1 },
  { year: 1908, num: 26.9 },
  { year: 1909, num: 30.1 },
  { year: 1910, num: 31.5 },
  { year: 1911, num: 32.4 },
  { year: 1912, num: 35.1 },
  { year: 1913, num: 36.8 },
  { year: 1914, num: 34.5 },
  { year: 1915, num: 36.5 },
  { year: 1916, num: 46.1 },
  { year: 1917, num: 55.1 },
  { year: 1918, num: 69.7 },
  { year: 1919, num: 77 },
  { year: 1920, num: 87.2 },
  { year: 1921, num: 73.3 },
  { year: 1922, num: 72.8 },
  { year: 1923, num: 85.3 },
  { year: 1924, num: 87.7 },
  { year: 1925, num: 91.2 },
  { year: 1926, num: 97.2 },
  { year: 1927, num: 96.1 },
  { year: 1928, num: 97 },
  { year: 1929, num: 103.6 },
  { year: 1930, num: 91.2 },
  { year: 1931, num: 76.5 },
  { year: 1932, num: 58.7 },
  { year: 1933, num: 56.4 },
  { year: 1934, num: 66 },
  { year: 1935, num: 73.3 },
  { year: 1936, num: 83.8 },
  { year: 1937, num: 91.9 },
  { year: 1938, num: 86.1 },
  { year: 1939, num: 92.2 },
  { year: 1940, num: 101.4 },
  { year: 1941, num: 126.7 },
  { year: 1942, num: 161.9 },
  { year: 1943, num: 198.6 },
  { year: 1944, num: 219.8 },
  { year: 1945, num: 223.1 },
  { year: 1946, num: 222.3 },
  { year: 1947, num: 244.2 },
  { year: 1948, num: 269.2 },
  { year: 1949, num: 267.3 },
  { year: 1950, num: 293.8 },
  { year: 1951, num: 339.3 },
  { year: 1952, num: 358.3 },
  { year: 1953, num: 379.4 },
  { year: 1954, num: 380.4 },
  { year: 1955, num: 414.8 },
  { year: 1956, num: 437.5 },
  { year: 1957, num: 461.1 },
  { year: 1958, num: 467.2 },
  { year: 1959, num: 506.6 },
  { year: 1960, num: 526.4 },
  { year: 1961, num: 544.7 },
  { year: 1962, num: 585.6 },
  { year: 1963, num: 617.7 },
  { year: 1964, num: 663.6 },
  { year: 1965, num: 719.1 },
  { year: 1966, num: 787.8 },
  { year: 1967, num: 832.6 },
  { year: 1968, num: 910 },
  { year: 1969, num: 984.6 },
  { year: 1970, num: 1038.5 },
  { year: 1971, num: 1127.1 },
  { year: 1972, num: 1238.3 },
  { year: 1973, num: 1382.7 },
  { year: 1974, num: 1500 },
  { year: 1975, num: 1638.3 },
  { year: 1976, num: 1825.3 },
  { year: 1977, num: 2030.9 },
  { year: 1978, num: 2294.7 },
  { year: 1979, num: 2563.3 },
  { year: 1980, num: 2789.5 },
  { year: 1981, num: 3128.4 },
  { year: 1982, num: 3255 },
  { year: 1983, num: 3536.7 },
  { year: 1984, num: 3933.2 },
  { year: 1985, num: 4220.3 },
  { year: 1986, num: 4462.8 },
  { year: 1987, num: 4739.5 },
  { year: 1988, num: 5103.8 },
  { year: 1989, num: 5484.4 },
  { year: 1990, num: 5803.1 },
  { year: 1991, num: 5995.9 },
  { year: 1992, num: 6337.7 },
  { year: 1993, num: 6657.4 },
  { year: 1994, num: 7072.2 },
  { year: 1995, num: 7397.7 },
  { year: 1996, num: 7816.9 },
  { year: 1997, num: 8304.3 },
  { year: 1998, num: 8747 },
  { year: 1999, num: 9268.4 },
  { year: 2000, num: 9817 },
  { year: 2001 },
  { year: 2002 },
  { year: 2003 },
  { year: 2004 },
  { year: 2005 },
  { year: 2006 },
  { year: 2007 },
  { year: 2008 },
  { year: 2009 },
  { year: 2010 },
  { year: 2011 },
  { year: 2012 },
  { year: 2013 },
  { year: 2014 },
  { year: 2015 },
  { year: 2016 },
  { year: 2017 },
  { year: 2018 },
  { year: 2019 },
  { year: 2020 },
  { year: 2021 }
];

const popdata = [
  {
    year: 1790,
    pop: 3929214
  },
  {
    year: 1800,
    pop: 5308483
  },
  {
    year: 1810,
    pop: 7239881
  },
  {
    year: 1820,
    pop: 9638453
  },
  {
    year: 1830,
    pop: 12860702
  },
  {
    year: 1840,
    pop: 17063353
  },
  {
    year: 1850,
    pop: 23191876
  },
  {
    year: 1860,
    pop: 31443321
  },
  {
    year: 1870,
    pop: 38558371
  },
  {
    year: 1880,
    pop: 50189209
  },
  {
    year: 1890,
    pop: 62979766
  },
  {
    year: 1900,
    pop: 76212168
  },
  {
    year: 1910,
    pop: 92228496
  },
  {
    year: 1920,
    pop: 106021537
  },
  {
    year: 1930,
    pop: 123202624
  },
  {
    year: 1940,
    pop: 142164569
  },
  {
    year: 1950,
    pop: 161325798
  },
  {
    year: 1960,
    pop: 189323175
  },
  {
    year: 1970,
    pop: 213302031
  },
  {
    year: 1980,
    pop: 236542199
  },
  {
    year: 1990,
    pop: 258709873
  },
  {
    year: 2000,
    pop: 291421906
  },
  {
    year: 2010,
    pop: 308745538
  },
  {
    year: 2020,
    pop: 333005258
  },
  {
    year: 2030,
    pop: 349655258
  },
  {
    year: 2040,
    pop: 366305258
  }
];

class GDPchild extends React.Component {
  constructor(props) {
    super(props);

    let testingData = [];
    let testing = [];
    let noData = [];
    let date = [];
    props.data.map((x) => {
      date.push(x.year);
      const gdppop = x.num / x.pop;
      testing.push(gdppop);
      testingData.push([x.year, gdppop]);
      return noData.push([x.year, 0]);
    });
    var highDate = Math.max(...date);
    var lowTesting = Math.min(...testing);
    var lowDate = Math.min(...date);
    var highTesting = Math.max(...testing);
    noData.sort((a, b) => a[0] - b[0]);
    testingData.sort((a, b) => a[0] - b[0]);

    var state = {
      highTesting,
      testingData,
      noData,
      yAxis: highTesting - lowTesting,
      xAxis: highDate - lowDate,
      lowTesting,
      highDate,
      lowDate
    };
    this.state = state;
  }
  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      let testingData = [];
      let testing = [];
      let noData = [];
      let date = [];
      this.props.data.map((x) => {
        date.push(x.year);
        const gdppop = x.num / x.pop;
        testing.push(gdppop);
        testingData.push([x.year, gdppop]);
        return noData.push([x.year, 0]);
      });
      var highDate = Math.max(...date);
      var lowTesting = Math.min(...testing);
      var lowDate = Math.min(...date);
      var highTesting = Math.max(...testing);
      noData.sort((a, b) => a[0] - b[0]);
      testingData.sort((a, b) => a[0] - b[0]);
      this.setState({
        highTesting,
        testingData,
        noData,
        yAxis: highTesting - lowTesting,
        xAxis: highDate - lowDate,
        lowTesting,
        highDate,
        lowDate
      });
    }
  };
  render() {
    if (this.props.data) {
      return (
        <div
          style={{
            height: "500px",
            display: "flex",
            position: "relative",
            width: "100%"
          }}
        >
          <div
            style={{
              maxWidth: "50%",
              height: "min-content",
              display: "flex",
              position: "absolute",
              right: "20px",
              flexDirection: "column",
              zIndex: "1",
              backgroundColor: "rgba(250,250,250,.6)"
            }}
          >
            ${Math.round(this.state.lowTesting * 100) / 100}/person -&nbsp;
            <br />${Math.round(this.state.highTesting * 100) / 100}/person
            <div
              style={{
                height: "min-content",
                display: "flex",
                position: "relative",
                right: "0px"
              }}
            >
              {this.state.lowDate}
              &nbsp;-&nbsp;
              {this.state.highDate}
            </div>
          </div>
          {this.props.width && (
            <svg
              className="all"
              style={{
                display: "flex",
                position: "absolute",
                width: "100%",
                height: "500px",
                transform: "scale(1,-1)"
              }}
              xmlns="http://www.w3.org/2000/svg"
            >
              {
                <g>
                  {this.state.noData.map(([x, y], i) => {
                    const transformation = [
                      ((x - this.state.lowDate) / this.state.xAxis) *
                        0.9 *
                        this.props.width,
                      0 // ((y - this.state.lowTesting) / this.state.yAxis) * 150
                    ];
                    return (
                      <rect
                        x={transformation[0]}
                        y={transformation[1]}
                        width={4}
                        height="100%"
                        stroke="rgb(230,230,230)"
                        fill="grey"
                        strokeWidth={2}
                        key={i}
                      />
                    );
                  })}
                  {this.state.testingData.map(([x, y], i) => {
                    const transformation = [
                      ((x - this.state.lowDate) / this.state.xAxis) *
                        0.9 *
                        this.props.width,
                      ((y - this.state.lowTesting) / this.state.yAxis) * 150
                    ];
                    return (
                      <rect
                        x={transformation[0]}
                        y={transformation[1]}
                        width={2}
                        height="100%"
                        stroke="rgb(230,230,230)"
                        fill="rgb(230,230,230)"
                        strokeWidth={1}
                        key={i}
                      />
                    );
                  })}
                  {this.state.testingData.map(([x, y], i) => {
                    const transformation = [
                      ((x - this.state.lowTesting) / this.state.xAxis) *
                        0.9 *
                        this.props.width,
                      ((y - this.state.lowTesting) / this.state.yAxis) * 150
                    ];
                    return (
                      <rect
                        x={transformation[0]}
                        y={transformation[1]}
                        width={4}
                        height={4}
                        stroke="black"
                        fill="black"
                        strokeWidth={2}
                        key={i}
                      />
                    );
                  })}
                </g>
              }
            </svg>
          )}
          <div
            style={{
              backgroundColor: "rgba(250,250,250,0.6)",
              top: "10px",
              height: "40px",
              display: "flex",
              position: "relative",
              maxWidth: "40%",
              width: "250px",
              left: "2px",
              zIndex: "0",
              overflowX: "auto",
              overflowY: "hidden"
            }}
          >
            <div
              style={{
                fontSize: "15px",
                display: "flex",
                position: "absolute",
                width: "max-content"
              }}
            >
              Gross Domestic Product per population/person/cohort/capita
              {/*<div style={{ width: "min-content" }}>
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    backgroundColor: "orange"
                  }}
                />
                covid19&nbsp;(+)&nbsp;&nbsp;
              </div>
              <div style={{ width: "min-content" }}>
                <div
                  style={{
                    width: "5px",
                    height: "5px",
                    backgroundColor: "black"
                  }}
                />
                num
                </div>*/}
            </div>
          </div>
        </div>
      );
    } else return null;
  }
}

class GDP extends React.Component {
  constructor(props) {
    super(props);
    const popdatapre2010 = popdata.filter((x) => x.year < 2010);
    this.state = {
      chosenDecade: 2000,
      chosenDecadeInx: popdatapre2010.length,
      data: gdpdata
        .map((x) => {
          var foo = { ...x };
          var b4Idx = null;
          var thisdecade = popdata.find((p, i) => {
            b4Idx = i;
            return x.year - p.year < 10 && x.year - p.year > -1;
          });
          if (foo.num) {
            foo.num = foo.num * 1000000000;
          }
          var b4pop = popdata[b4Idx - 1] ? popdata[b4Idx - 1].pop : 0;
          var b4year = popdata[b4Idx - 1] ? popdata[b4Idx - 1].year : 0;
          var addi = (thisdecade.pop - b4pop) * ((x.year - b4year) / 10);
          foo.pop = thisdecade.pop + addi;
          return foo;
        })
        .filter((x) => x.year < 2000 + 1 && 2000 - x.year < 120),
      chosenState: "Florida",
      last: 1000,
      crime: true
    };
  }
  componentDidUpdate = (prevProps) => {
    if (this.state.chosenDecadeInx !== this.state.lastChosenDecadeInx) {
      // var chosenstate = [].find((x) => x.name === this.state.chosenState);
      this.setState(
        { lastChosenDecadeInx: this.state.chosenDecadeInx },
        async () => {
          const copy = gdpdata.map((x) => {
            var foo = { ...x };
            var b4Idx = null;
            var thisdecade = popdata.find((p, i) => {
              b4Idx = i;
              return x.year - p.year < 10 && x.year - p.year > -1;
            });
            foo.num = foo.num * 1000000000;
            var b4pop = popdata[b4Idx - 1] ? popdata[b4Idx - 1].pop : 0;
            var b4year = popdata[b4Idx - 1] ? popdata[b4Idx - 1].year : 0;
            var addi = (thisdecade.pop - b4pop) * ((x.year - b4year) / 10);
            foo.pop = thisdecade.pop + addi;
            return foo;
          });
          this.setState({
            data: copy.filter(
              (x) =>
                x.year < this.state.chosenDecade + 1 &&
                this.state.chosenDecade - x.year < 120
            )
          });
        }
      );
    }
  };
  render() {
    const { data } = this.state;
    /*var statesAvailable = [];
    [].map((x) => {
      return statesAvailable.push(x.jurisdiction_of_occurrence);
    });
    var filteredByState = this.state.data.filter(
      (x) => x.jurisdiction_of_occurrence === this.state.chosenState
    );
    var unique = [...new Set(statesAvailable)];
    var statesGood = unique.filter((x) =>
       unique.includes(
        x.jurisdiction_of_occurrence
      )
    );*/
    //console.log(this.props.covidData);
    //if (this.state.chosenState === this.state.lastChosenState) {
    const popdatapre2010 = popdata.filter((x) => x.year < 2010);
    return (
      <div
        style={{
          top: "0px",
          display: "flex",
          position: "relative",
          width: "100%",
          height: "min-content",
          flexDirection: "column"
        }}
      >
        <div
          style={{
            position: "relative",
            top: "0px",
            height: "min-content",
            flexWrap: "wrap",
            display: "flex"
          }}
        >
          {
            //unique
            [].map((x, i) => {
              return (
                <div
                  key={i}
                  style={{
                    alignItems: "center",
                    padding: "4px 7px",
                    border:
                      this.state.chosenState === x ? "1px solid black" : "",
                    display: "flex"
                  }}
                >
                  <div onClick={() => this.setState({ chosenState: x })}>
                    {
                      //x
                    }
                  </div>
                  &nbsp;
                  {this.state.chosenState === x && (
                    <button
                      onClick={() => this.setState({ chosenState: null })}
                    >
                      &times;
                    </button>
                  )}
                </div>
              );
            })
          }
        </div>
        cases to tests
        {
          //filteredByState.length > 0 && (
          <GDPchild
            width={this.props.width}
            data={data} //filteredByState
            crime={this.state.crime}
            chosenState={this.state.chosenState}
          />
          //
        }
        <div
          style={{
            margin: "10px 50px",
            display: "flex",
            width: "70%",
            justifyContent: "space-between"
          }}
        >
          <div
            style={{ border: "1px solid", padding: "10px", userSelect: "none" }}
            onClick={() => {
              if (this.state.chosenDecadeInx > 0)
                this.setState(
                  { chosenDecadeInx: this.state.chosenDecadeInx - 1 },
                  () => {
                    this.setState({
                      chosenDecade:
                        popdatapre2010[this.state.chosenDecadeInx].year
                    });
                  }
                );
            }}
          >
            {"<"}
          </div>
          {this.state.chosenDecade}
          <div
            style={{ border: "1px solid", padding: "10px", userSelect: "none" }}
            onClick={() => {
              if (this.state.chosenDecadeInx < popdatapre2010.length - 1)
                this.setState(
                  { chosenDecadeInx: this.state.chosenDecadeInx + 1 },
                  () => {
                    this.setState({
                      chosenDecade:
                        popdatapre2010[this.state.chosenDecadeInx].year
                    });
                  }
                );
            }}
          >
            {">"}
          </div>
        </div>
      </div>
    );
  }
}
export default GDP;
